import { useIntl, IntlShape } from 'react-intl';
import { Alert } from '../../components';
import messages from './messages';
import { createPhoneVerificationMutation_createPhoneVerification } from '../../core-types';

export function getCreateVerificationErrorMessage({
  createPhoneVerification,
  intl
}: {
  createPhoneVerification: createPhoneVerificationMutation_createPhoneVerification;
  intl: IntlShape;
}): string | null {
  let createVerificationError;
  switch (createPhoneVerification.__typename) {
    case 'PhoneVerification': {
      break;
    }
    case 'PhoneVerificationError': {
      return createPhoneVerification.message;
      break;
    }
    case 'PhoneVerificationRateLimit': {
      return (
        createPhoneVerification.message +
        ' ' +
        intl.formatMessage(messages.retryIn, {
          ttlRelative: intl.formatRelativeTime(
            Math.round(createPhoneVerification.ttl / 60),
            'minute'
          )
        })
      );
    }
    default: {
      break;
    }
  }

  return createVerificationError;
}

export function CreateVerificationErrorAlert({
  createPhoneVerification,
  ...props
}: {
  createPhoneVerification: createPhoneVerificationMutation_createPhoneVerification;
}) {
  const intl = useIntl();

  let createVerificationError = getCreateVerificationErrorMessage({
    createPhoneVerification,
    intl
  });

  if (!createVerificationError) {
    return null;
  }

  return (
    <Alert variant="error" mb="sm" {...props}>
      {createVerificationError}
    </Alert>
  );
}
