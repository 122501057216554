import { gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { Input, InputGroup, Label } from '../../../components';
import { CountryCodeSelect } from './CountryCodeSelect';
import { Field, FieldProps } from 'formik';
import { CreateVerificationValues } from '../types';

export const PHONE_COUNTRY_CODES_QUERY = gql`
  query phoneCountryCodesQuery {
    phoneCountryCodes {
      code
      countryName
    }
  }
`;

export function PhoneNumberInput({
  countryCode,
  onCountryCodeChange
}: {
  countryCode: string;
  onCountryCodeChange: (countryCode: string) => void;
}) {
  return (
    <>
      <Label htmlFor="phoneNumberInput">
        <FormattedMessage id="phone_number" defaultMessage="Phone number" />
      </Label>
      <InputGroup width={300}>
        <CountryCodeSelect
          bg="gray"
          value={countryCode}
          onChange={onCountryCodeChange}
        />
        <Field name="nationalPhoneNumber" width={300}>
          {(
            props: FieldProps<CreateVerificationValues['nationalPhoneNumber']>
          ) => {
            return (
              <Input
                width={300}
                id="phoneNumberInput"
                type="tel"
                autoComplete="tel-national"
                autoFocus
                {...props.field}
              />
            );
          }}
        </Field>
      </InputGroup>
    </>
  );
}
