import { defineMessages } from 'react-intl';

const messages = defineMessages({
  phoneNumberInfo: {
    id: 'modules.BuyProduct.Checkout.PhoneNumberInfo',
    defaultMessage:
      'We use your phone number to sign you in, and to contact you if something should be wrong. {privacyInformation}'
  },
  personalInformation: {
    id: 'modules.BuyProduct.Checkout.PersonalInformation',
    defaultMessage: 'Read about how we treat personal information'
  },
  alreadyLoggedIn: {
    id: 'modules.BuyProduct.Checkout.AlreadyLoggedIn',
    defaultMessage: 'You are logged in as {phoneNumber}'
  },
  continueWithThisMember: {
    id: 'modules.BuyProduct.Checkout.ContinueWithThisMember',
    defaultMessage: 'Continue with this member'
  },
  useOtherPhoneNumber: {
    id: 'modules.BuyProduct.Checkout.UserOtherPhoneNumber',
    defaultMessage:
      'Not your phone number? {logOutButton} to use a different one.'
  },
  alreadyVerified: {
    id: 'modules.BuyProduct.Checkout.AlreadyVerified',
    defaultMessage: 'You are verified with {phoneNumber}'
  },
  continueWithThisVerification: {
    id: 'modules.BuyProduct.Checkout.ContinueWithThisVerification',
    defaultMessage: 'Continue with this phone number'
  },
  oneTime: {
    id: 'modules.BuyProduct.Checkout.OneTime',
    defaultMessage: 'One-time code'
  },
  phoneNumberRequired: {
    id: 'modules.VerifyPhoneNumber.phoneNumberRequired',
    defaultMessage: 'Enter your phone number'
  },
  retryIn: {
    id: 'modules.VerifyPhoneNumber.retryIn',
    defaultMessage: 'Try again {ttlRelative}.'
  },
  termsNotAccepted: {
    id: 'modules.BuyProduct.SummaryStep.missingTosAccept',
    defaultMessage: 'Terms of Use must be accepted'
  }
});

export default messages;
