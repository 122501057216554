import * as React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';
import { CREATE_VERIFICATION } from './graphql';
import { Formik, FormikErrors } from 'formik';
import {
  AnchorButton,
  Alert,
  ButtonGroup,
  LoadableButton,
  Box,
  Text,
  FormGroup
} from '../../components';
import { SETTINGS_QUERY } from '../../lib/core/SettingsQuery';
import { globalMessages } from '../../lib/messages';
import messages from './messages';
import { getCreateVerificationErrorMessage } from './CreateVerificationErrorAlert';
import { useStoredVerification } from './useStoredVerification';
import {
  settings as settingsQuery,
  createPhoneVerificationMutation,
  createPhoneVerificationMutationVariables,
  VerificationMode,
  ConsentEnforcement,
  LegalConsentInput
} from '../../core-types';
import { PhoneNumberInput } from './PhoneNumberInput';
import {
  CreateVerificationInitialValues,
  CreateVerificationValues
} from './types';
import routeConfig from '../../lib/routes/config';
import { ConsentCheckBox } from '../TermsAndConditions';
import { useRouter } from 'next/router';

export function enforceLegalConsents(
  legalConsents: LegalConsentInput[],
  consents: {
    terms_of_use_and_privacy_policy: boolean;
    registration_agreement_withdrawal: boolean;
    subscription_agreement_withdrawal: boolean;
    send_data_to_third_parties: boolean;
  }
): boolean {
  for (const consent of legalConsents) {
    if (
      consent.enforcement === ConsentEnforcement.required &&
      consents[consent.type] === false
    ) {
      return false;
    }
  }
  return true;
}

export function CreateVerification({
  center,
  initialValues,
  additionalButton,
  mode,
  setMode,
  onCodeSent,
  label,
  onUseCodeClick
}: {
  additionalButton?: React.ReactNode;
  center?: boolean;
  initialValues: CreateVerificationInitialValues;
  mode: VerificationMode;
  setMode: (VerificationMode) => void;
  onCodeSent: (
    obj: CreateVerificationValues & { twilioVerificationSid: string }
  ) => void;
  label?: React.ReactNode;
  onUseCodeClick: (
    obj: CreateVerificationValues & { twilioVerificationSid: string }
  ) => void;
}): JSX.Element {
  const intl = useIntl();
  const router = useRouter();

  const [consents, setConsents] = React.useState({
    terms_of_use_and_privacy_policy: false,
    registration_agreement_withdrawal: false,
    subscription_agreement_withdrawal: false,
    send_data_to_third_parties: false
  });

  const { data: settingsData } = useQuery<settingsQuery, {}>(SETTINGS_QUERY);
  if (!initialValues.prefix) {
    if (
      settingsData &&
      settingsData.system &&
      settingsData.system.phoneCountryCode
    ) {
      initialValues.prefix = settingsData.system.phoneCountryCode;
    } else {
      initialValues.prefix = '+47';
    }
  }

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConsents({
      ...consents,
      [event.target.name]: event.target.checked
    });
  };
  React.useEffect(() => {
    localStorage.setItem('consents', JSON.stringify(consents));
  }, [consents]);

  const {
    terms_of_use_and_privacy_policy,
    registration_agreement_withdrawal,
    subscription_agreement_withdrawal,
    send_data_to_third_parties
  } = consents;

  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();
  const [createVerification, { loading, error, data }] = useMutation<
    createPhoneVerificationMutation,
    createPhoneVerificationMutationVariables
  >(CREATE_VERIFICATION);
  const [storedVerification, setStoreVerification] = useStoredVerification();

  const slug = router?.query.slug as string;
  // Hide consent checkboxes on certain pages
  const excludePages = ['log-in', 'profile'];
  const hideConsentCheckBoxes = excludePages
    .map(page => {
      return Object.values(routeConfig[page].slug || {}).includes(slug);
    })
    .includes(true);

  async function onSubmit(values: CreateVerificationInitialValues) {
    if (
      !hideConsentCheckBoxes &&
      settingsData &&
      settingsData.system &&
      settingsData.system.legalConsents
    ) {
      const legalConsents = settingsData.system.legalConsents;
      const legalConsentsAccepted = enforceLegalConsents(
        legalConsents,
        consents
      );
      if (!legalConsentsAccepted) {
        setErrorMessage(intl.formatMessage(messages.termsNotAccepted));
        return;
      }
    }
    createVerification({
      variables: {
        input: {
          mode,
          phoneNumber: values.prefix + values.nationalPhoneNumber
        }
      }
    })
      .then(({ data }) => {
        if (!data || !data.createPhoneVerification) {
          return setErrorMessage(
            intl.formatMessage(globalMessages.genericError)
          );
        }
        const { createPhoneVerification } = data;
        if (createPhoneVerification.__typename === 'PhoneVerification') {
          const valuesWithVerificationSid = {
            ...values,
            twilioVerificationSid: createPhoneVerification.sid
          };
          setStoreVerification(valuesWithVerificationSid);
          onCodeSent(valuesWithVerificationSid);
          return;
        }
        // send code if user already exists, and user not trying to update phone number
        if (
          mode !== VerificationMode.update &&
          createPhoneVerification.__typename === 'PhoneVerificationError' &&
          createPhoneVerification.errorCode === 'ERR_IN_USE_PHONE_NUMBER'
        ) {
          setMode(VerificationMode.login);
          createVerification({
            variables: {
              input: {
                mode: VerificationMode.login,
                phoneNumber: values.prefix + values.nationalPhoneNumber
              }
            }
          }).then(({ data }) => {
            if (!data || !data.createPhoneVerification) {
              return setErrorMessage(
                intl.formatMessage(globalMessages.genericError)
              );
            }
            const { createPhoneVerification } = data;
            if (createPhoneVerification.__typename === 'PhoneVerification') {
              const valuesWithVerificationSid = {
                ...values,
                twilioVerificationSid: createPhoneVerification.sid
              };
              setStoreVerification(valuesWithVerificationSid);
              onCodeSent(valuesWithVerificationSid);
              return;
            }
            const errorMessage = getCreateVerificationErrorMessage({
              createPhoneVerification,
              intl
            });
            if (errorMessage) {
              setErrorMessage(errorMessage);
            }
          });
        } else {
          const errorMessage = getCreateVerificationErrorMessage({
            createPhoneVerification,
            intl
          });
          if (errorMessage) {
            setErrorMessage(errorMessage);
          }
        }
      })
      .catch(() => {
        setErrorMessage(intl.formatMessage(globalMessages.genericError));
      });
  }

  return (
    <Formik<CreateVerificationValues>
      initialValues={initialValues}
      validate={values => {
        let errors: FormikErrors<CreateVerificationValues> = {};
        if (!values.nationalPhoneNumber) {
          errors.nationalPhoneNumber = intl.formatMessage(
            messages.phoneNumberRequired
          );
        }
        if (!values.prefix) {
          errors.prefix = 'Required';
        }
        return errors;
      }}
      onSubmit={onSubmit}
    >
      {({
        setFieldValue,
        submitForm,
        isValid,
        values,
        errors,
        submitCount
      }) => {
        const submitButton = (
          <LoadableButton
            type="submit"
            variant="primary"
            shape="cool"
            loading={loading}
            responseState={
              error ||
              data?.createPhoneVerification.__typename !== 'PhoneVerification'
                ? 'error'
                : 'success'
            }
          >
            <FormattedMessage
              id="modules.VerifyPhoneNumber.CreateVerification.sendCodeButton"
              defaultMessage="Send SMS code"
            />
          </LoadableButton>
        );
        return (
          <form
            id="create-verification-form"
            data-testid="CreateVerification__Form"
            onSubmit={event => {
              event.preventDefault();
              submitForm();
            }}
          >
            {errorMessage && (
              <Alert variant="error" mb="sm">
                {errorMessage}
              </Alert>
            )}
            {submitCount > 0 && !isValid && errors.nationalPhoneNumber && (
              <Alert variant="error" mb="sm">
                {errors.nationalPhoneNumber}
              </Alert>
            )}
            <Box mx={center ? 'auto' : undefined} mb="xs" maxWidth={360}>
              <PhoneNumberInput
                countryCode={values.prefix}
                onCountryCodeChange={countryCode =>
                  setFieldValue('prefix', countryCode)
                }
              />
            </Box>
            {/* Show consent boxes during verification, except for the login page */}
            {!hideConsentCheckBoxes && (
              <FormGroup mb={5}>
                {settingsData?.system?.legalConsents?.map(consent => {
                  return (
                    <Box mb={4} key={consent.type}>
                      <ConsentCheckBox
                        consentType={consent.type}
                        settingsData={settingsData}
                        terms_of_use_and_privacy_policy={
                          terms_of_use_and_privacy_policy
                        }
                        registration_agreement_withdrawal={
                          registration_agreement_withdrawal
                        }
                        subscription_agreement_withdrawal={
                          subscription_agreement_withdrawal
                        }
                        send_data_to_third_parties={send_data_to_third_parties}
                        handleCheckBoxChange={handleCheckBoxChange}
                      />
                    </Box>
                  );
                })}
              </FormGroup>
            )}

            {additionalButton && (
              <ButtonGroup>
                {submitButton}
                {additionalButton}
              </ButtonGroup>
            )}
            {!additionalButton && (
              <Box textAlign={center ? 'center' : 'left'}>{submitButton}</Box>
            )}

            {label && (
              <Text mute small mt="sm">
                {label}
              </Text>
            )}

            <Text mute small mt="xs" textAlign={center ? 'center' : 'left'}>
              {storedVerification && (
                <Text mb="xs" data-testid="CreateVerification__UseCode">
                  <AnchorButton
                    type="button"
                    onClick={() => {
                      onUseCodeClick({
                        twilioVerificationSid:
                          storedVerification.twilioVerificationSid,
                        nationalPhoneNumber:
                          storedVerification.nationalPhoneNumber,
                        prefix: storedVerification.prefix
                      });
                    }}
                  >
                    <FormattedMessage
                      id="modules.VerifyPhoneNumber.CreateVerification.useCode"
                      defaultMessage="Already have a code?"
                    />
                  </AnchorButton>
                </Text>
              )}
            </Text>
          </form>
        );
      }}
    </Formik>
  );
}
