import { useState, useEffect, useRef } from 'react';
import Downshift from 'downshift';
import {
  InputGroupAddon,
  Dropdown,
  DropdownItem,
  DropdownInput,
  Button
} from '../../../components';
import {
  phoneCountryCodesQuery_phoneCountryCodes,
  phoneCountryCodesQuery
} from '../../../core-types';
import { useIntl, defineMessages } from 'react-intl';
import { useQuery } from '@apollo/client';
import { PHONE_COUNTRY_CODES_QUERY } from '.';
import { Box } from '@material-ui/core';

type Props = {
  bg: string;
  value: string;
  onChange: (countryCode: string) => void;
};

function itemToString(
  item: phoneCountryCodesQuery_phoneCountryCodes | null
): string {
  return item ? `${item.code}` : '';
}

const messages = defineMessages({
  filterInputLabel: {
    id: 'PhoneNumberInput.label',
    defaultMessage: 'Search by country or code'
  }
});

export function CountryCodeSelect({ onChange, value }: Props): JSX.Element {
  const intl = useIntl();
  const { data } = useQuery<phoneCountryCodesQuery>(PHONE_COUNTRY_CODES_QUERY);
  const countryCodes = data?.phoneCountryCodes || [];
  const filterInputRef = useRef<HTMLInputElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  useEffect(() => {
    if (isOpen && filterInputRef.current) {
      filterInputRef.current.focus();
    }
  }, [isOpen]);
  function closeAndReset() {
    setIsOpen(false);
    setFilterValue('');
  }
  return (
    <Downshift
      onChange={({ code }) => {
        onChange(code);
        closeAndReset();
      }}
      isOpen={isOpen}
      onOuterClick={() => closeAndReset()}
      itemToString={itemToString}
      inputValue={value}
    >
      {({
        getRootProps,
        getInputProps,
        getItemProps,
        isOpen,
        inputValue,
        highlightedIndex
      }) => {
        const inputProps = getInputProps();
        const filterInputLabel = intl.formatMessage(messages.filterInputLabel);
        return (
          <InputGroupAddon
            {...getRootProps()}
            position="relative"
            alternative
            px={0}
            py={0}
          >
            <Button
              variant={isOpen ? 'primary' : 'reset'}
              style={{ height: '100%' }}
              onClick={() => setIsOpen(!isOpen)}
              onKeyDown={event => {
                // on arrow down, open
                if (event.keyCode === 40) {
                  setIsOpen(true);
                }
              }}
            >
              {inputValue}
            </Button>
            {isOpen && (
              <Dropdown
                width={360}
                left={0}
                style={{ marginTop: '5px' }}
                right="auto"
              >
                <DropdownInput
                  width={360}
                  ref={filterInputRef}
                  //aria-label={filterInputLabel}
                  placeholder={filterInputLabel}
                  onKeyDown={inputProps.onKeyDown}
                  value={filterValue}
                  onChange={event =>
                    setFilterValue(event.target.value.toLowerCase())
                  }
                />
                <Box
                  maxHeight="150px"
                  overflow="scroll"
                  css={{
                    '&::-webkit-scrollbar': {
                      display: 'none'
                    }
                  }}
                >
                  {countryCodes &&
                    countryCodes.reduce<React.ReactNode[]>(
                      (accum, item, index) => {
                        if (
                          item &&
                          (item.code.includes(filterValue) ||
                            item.countryName
                              .toLowerCase()
                              .includes(filterValue))
                        ) {
                          accum.push(
                            <DropdownItem
                              {...getItemProps({ item })}
                              key={item.countryName}
                              p={2}
                              selected={
                                inputValue === item.code ||
                                highlightedIndex === index
                              }
                            >
                              {item.countryName} ({item.code})
                            </DropdownItem>
                          );
                        }
                        return accum;
                      },
                      []
                    )}
                </Box>
              </Dropdown>
            )}
          </InputGroupAddon>
        );
      }}
    </Downshift>
  );
}
