import React from 'react';
import { FormattedMessage } from 'react-intl';

import { LegalConsentType, settings as settingsQuery } from '../../core-types';
import { CheckboxProps, FormControlLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { useStyledTheme } from '../../lib/hooks';
import { Anchor, Link } from '../../components';
import { makeStyles } from '@material-ui/core';

export interface ConsentCheckBoxProps {
  consentType: LegalConsentType;
  settingsData?: settingsQuery;
  terms_of_use_and_privacy_policy: boolean;
  registration_agreement_withdrawal: boolean;
  subscription_agreement_withdrawal: boolean;
  send_data_to_third_parties: boolean;
  handleCheckBoxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ConsentCheckBox: React.FC<ConsentCheckBoxProps> = props => {
  const {
    consentType,
    settingsData,
    terms_of_use_and_privacy_policy,
    registration_agreement_withdrawal,
    subscription_agreement_withdrawal,
    send_data_to_third_parties,
    handleCheckBoxChange
  } = props;

  const theme = useStyledTheme();

  const StyledCheckbox = withStyles({
    root: {
      fontFamily: theme.fonts.heading,
      '&:hover': {
        backgroundColor: 'transparent'
      },
      color: theme.colors.primary,
      '&$checked': {
        color: theme.colors.primary
      }
    },
    checked: {}
  })((props: CheckboxProps) => <Checkbox color="default" {...props} />);

  const useStyles = makeStyles(() => ({
    label: {
      fontFamily: theme.fonts.heading
    }
  }));

  const classes = useStyles();

  switch (consentType) {
    case LegalConsentType.terms_of_use_and_privacy_policy:
      return (
        <FormControlLabel
          classes={{ label: classes.label }}
          control={
            <StyledCheckbox
              id="terms_of_use_and_privacy_policy"
              checked={terms_of_use_and_privacy_policy}
              onChange={handleCheckBoxChange}
              name="terms_of_use_and_privacy_policy"
            />
          }
          label={
            <FormattedMessage
              id={
                settingsData?.system?.id === 'inurba-gdansk'
                  ? 'modules.BuyProduct.agreeToTermsAndPrivacyPolicyGdansk'
                  : 'modules.BuyProduct.agreeToTermsAndPrivacyPolicy'
              }
              defaultMessage="I have read and downloaded the {termsOfUse} and {privacyPolicy}, and agree to abide by them."
              values={{
                privacyPolicy: (
                  <Link route="/privacy-policy" passHref>
                    <Anchor>
                      <FormattedMessage
                        id="modules.VerifyPhoneNumber.CreateVerification.recaptchaLabel.privacyPolicy"
                        defaultMessage="Privacy Policy"
                      />
                    </Anchor>
                  </Link>
                ),
                termsOfUse: (
                  <Link route="/terms-of-use" passHref>
                    <Anchor>
                      <FormattedMessage
                        id="modules.BuyProduct.termsOfUse"
                        defaultMessage="Terms of Use"
                      />
                    </Anchor>
                  </Link>
                )
              }}
            />
          }
        />
      );
    case LegalConsentType.registration_agreement_withdrawal:
      return (
        <FormControlLabel
          classes={{ label: classes.label }}
          control={
            <StyledCheckbox
              id="registration_agreement_withdrawal"
              checked={registration_agreement_withdrawal}
              onChange={handleCheckBoxChange}
              name="registration_agreement_withdrawal"
            />
          }
          label={
            <FormattedMessage
              id="terms_right_of_withdrawal_registration"
              defaultMessage="I agree to start using the Service before the end of the withdrawal period. I acknowledge that upon completion of the Registration, I lose my right to withdraw from the Agreement."
            />
          }
        />
      );
    case LegalConsentType.subscription_agreement_withdrawal:
      return (
        <FormControlLabel
          classes={{ label: classes.label }}
          control={
            <StyledCheckbox
              id="subscription_agreement_withdrawal"
              checked={subscription_agreement_withdrawal}
              onChange={handleCheckBoxChange}
              name="subscription_agreement_withdrawal"
            />
          }
          label={
            <FormattedMessage
              id="terms_right_of_withdrawal_subscription"
              defaultMessage="I agree to start using the Subscription Agreement before the end of the withdrawal period. I acknowledge that I forfeit my right to withdraw from the Subscription Agreement upon the Operator's full performance of the Subscription Agreement."
            />
          }
        />
      );
    case LegalConsentType.send_data_to_third_parties:
      return (
        <FormControlLabel
          classes={{ label: classes.label }}
          control={
            <StyledCheckbox
              id="send_data_to_third_parties"
              checked={send_data_to_third_parties}
              onChange={handleCheckBoxChange}
              name="send_data_to_third_parties"
            />
          }
          label={
            <FormattedMessage
              id="acceptTerms.showAgreedToSendDataToThirdPartiesLabel"
              defaultMessage="Share contact data with our partners for statistical and profiling purposes."
            />
          }
        />
      );
    default:
      return null;
  }
};
