import { gql } from '@apollo/client';
import { CURRENT_USER_FRAGMENT } from '../../lib/core/fragments/currentUser';

export const CREATE_VERIFICATION = gql`
  mutation createPhoneVerificationMutation(
    $input: CreatePhoneVerificationInput!
  ) {
    createPhoneVerification(input: $input) {
      __typename
      ... on PhoneVerification {
        sid
        to
      }
      ... on PhoneVerificationRateLimit {
        ttl
        message
      }
      ... on PhoneVerificationError {
        errorCode
        message
      }
    }
  }
`;

export const VERIFY_PHONE_CODE_AND_AUTHENTICATE = gql`
  ${CURRENT_USER_FRAGMENT}
  mutation verifyPhoneCodeAndAuthenticateMutation(
    $input: PhoneVerificationInput!
  ) {
    verifyPhoneCodeAndAuthenticate(input: $input) {
      __typename
      ... on SuccessfulAuthentication {
        jwt
        user {
          ...CurrentUser
        }
      }
      ... on PhoneVerificationError {
        errorCode
        message
      }
    }
  }
`;

export const LOGIN_MUTATION = gql`
  ${CURRENT_USER_FRAGMENT}
  mutation loginMutation($input: PhoneVerificationInput!) {
    verifyCodeAndLogIn(input: $input) {
      __typename
      ... on VerifyCodeAndLogInSuccess {
        jwt
        user {
          ...CurrentUser
        }
      }
      ... on PhoneVerificationError {
        errorCode
        message
      }
    }
  }
`;

export const UPDATE_PHONE_NUMBER_MUTATION = gql`
  mutation twilioUpdatePhoneNumberMutation($input: PhoneVerificationInput!) {
    updatePhoneNumber: verifyCodeAndUpdatePhoneNumber(input: $input) {
      __typename
      ... on VerifyCodeAndUpdatePhoneNumberSuccess {
        updatedPhoneNumber
      }
      ... on PhoneVerificationError {
        errorCode
        message
      }
    }
  }
`;

export const ACCEPT_MULTIPLE_CONSENTS_MUTATION = gql`
  mutation updateConsentsMutation($consents: [LegalConsentInput!]!) {
    updateLegalConsents(consents: $consents) {
      id
    }
  }
`;
